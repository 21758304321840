<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center border-b border-gray-200 pb-8">
      <h3 class="text-base md:text-24 leading-tight font-bold font-heading text-gray-800">Edit platforms</h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          :to="{ name: routeNames.platforms }"
          variant="danger"
          size="standart-sm"
        >
          Cancel
          <i class="icon-cross text-xs ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 flex-col overflow-y-auto pb-8 md:pb-40">
      <div class="w-full">
        <p class="font-medium text-left mb-4 text-blue-600 whitespace-normal text-18">Edit basic information</p>
        <div class="w-full mt-10">
          <div
            v-for="(item, index) in platforms"
            :key="index"
            class="w-full flex flex-col sm:flex-row mb-16 sm:mb-8"
          >
            <p class="min-w-192 w-192 text-gray-900 font-heading font-medium mr-16 whitespace-normal capitalize">
              {{ item.platform }}
            </p>
            <AppCheckbox
              :id="item.platform"
              v-model="item.active"
              :name="item.platform"
              label="Is Active"
            />
          </div>
        </div>
      </div>

      <div class="w-full flex justify-center pt-3 pb-6 md:pt-6 md:pb-10">
        <div class="w-full md:w-1/2">
          <AppButton
            :loading="loading"
            variant="primary"
            class="w-full"
            @click="onUpdateHandler"
          >
            Update
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import { routeNames } from '@/router'
import { notification, booleanValueToLabel } from '@/core/helpers'
import { usePlatforms } from '@/core/compositions'

import AppButton from '@/components/stateless/AppButton.vue'
import AppCheckbox from '@/components/stateless/AppCheckbox.vue'

export default defineComponent({
  name: 'Platforms',

  components: {
    AppButton,
    AppCheckbox
  },

  setup () {
    const router = useRouter()
    const { platforms, getPlatforms, updatePlatforms } = usePlatforms()

    const loading = ref(true)

    const getInitialData = async () => {
      loading.value = true

      try {
        await getPlatforms()
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const onUpdateHandler = async () => {
      loading.value = true

      try {
        await updatePlatforms()

        router.push({ name: routeNames.platforms })
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    // Run on start
    getInitialData()

    return {
      routeNames,
      loading,
      platforms,
      booleanValueToLabel,
      onUpdateHandler
    }
  }
})
</script>
